<template>
  <v-img
    :src="logoUrl"
    class="venue-logo"
    alt="Venue Logo"
  />
</template>

<script setup lang="ts">
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { computed } from 'vue';
import egImageUrl from '@/assets/images/eg_logo.svg';

const visitConditionsStore = useVisitConditionsStore();
const logoUrl = computed(() => visitConditionsStore.visitSettings?.onlineFormOptions?.logoUrl || egImageUrl);
</script>
<style lang="scss" scoped>
.venue-logo {
  max-width: 80%;
  margin: 60px auto;
}
</style>
