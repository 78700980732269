<template>
  <div class="section-content-wrapper">
    <LanguageSelect />
    <VenueLogo />
    <div class="welcome-text">
      <h1 class="text-h4 mb-4">{{ $translate('welcome') }}</h1>
      <div class="checked-in-text px-8 mt-32">
        {{ $translate(content) }}
      </div>
    </div>
    <v-btn
      class="mx-auto mt-6"
      size="large"
      elevation="0"
      min-width="70%"
      color="success"
      block
      data-qa="welcome-proceed-button"
      @click="next"
    >
      {{ $translate('checkin') }}
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { useVisitConditionsStore } from '@/store/visit-conditions';
import type { IVisitFormTextSection } from '@einfachgast/shared';
import VenueLogo from '@/components/VenueLogo.vue';
import { computed } from 'vue';
import LanguageSelect from '@/components/LanguageSelect.vue';
import { useSectionNavigation } from '@/dynamic-form/composables/use-section-navigation';
const visitConditionsStore = useVisitConditionsStore();
const welcomeSection = computed(() => visitConditionsStore.visitSettings?.visitFormSchema.sections.find((section) => section.type === 'welcomeText'));
const content = computed(() => (welcomeSection.value as IVisitFormTextSection)?.content);
const { next } = useSectionNavigation();
</script>
<style lang="scss" scoped>
.welcome-text {
  margin: 120px 0 32px 0;
  text-align: center;
}
</style>

