<template>
  <div
    v-if="langs.length > 1"
    class="lang-select-wrapper"
  >
    <div class="lang-select">
      <v-menu>
        <template #activator="{ props }">
          <v-img
            :src="`/languages/${i18nStore.selectedLanguage}.svg`"
            width="25"
            v-bind="props"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="lang in langs"
            :key="lang.value"
            :title="$translate(lang.text)"
            :class="{'text-primary': lang.value === i18nStore.selectedLanguage}"
            @click="changeLang(lang.value)"
          >
          <template #prepend>
            <div class="flag-wrapper">
              <v-img :src="`/languages/${lang.value}.svg`" width="25" />
            </div>
          </template>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18nStore } from '@/store/i18n';
import { i18n } from '../locale/i18n';
import type { Languages } from '@einfachgast/shared';

const i18nStore = useI18nStore();
const langs = i18nStore.availableLanguages.map((lang) => ({ text: lang, value: lang }));

const changeLang = (lang: Languages) => {
  i18nStore.selectLanguage(lang);
  i18n.global.locale.value = lang as Languages;
};
</script>
<style scoped lang="scss">
.lang-select-wrapper {
  display: flex;
  justify-content: flex-end;
}
.flag-wrapper {
  margin-right: 8px;
}

</style>